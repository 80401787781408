import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from '../pages/home';
import ExpertiseBatiment from '../pages/expertiseBatiment';
import Mediation from '../pages/mediation';
import Articles from '../pages/articles';
import Contact from '../pages/contact';
import MentionsLegales from '../pages/mentionsLegales';
import NoMatch from '../pages/nomatch';
import MaitriseDouvrage from '../pages/maitriseDouvrage';
import Article from '../pages/article';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

export default function CustomRouter() {

    return (
        <Router>
            <div>
                <Switch>
                    <Route exact path="/articles">
                        <Articles />
                    </Route>
                    <Route exact path="/expertise-batiment">
                        <ExpertiseBatiment />
                    </Route>
                    <Redirect from="/index.php/Page/mediation" to="/mediation" />
                    <Route exact path="/mediation">
                        <Mediation />
                    </Route>
                    <Route excat path="/assistance-maitrise-d-ouvrage">
                        <MaitriseDouvrage />
                    </Route>
                    <Redirect from="/index.php/Page/Contact" to="/contact" />
                    <Route exact path="/contact">
                        <Contact />
                    </Route>
                    <Route exact path="/mentions-legales">
                        <MentionsLegales />
                    </Route>
                    <Route exact path="/article/:slug">
                        <Article />
                    </Route>
                    <Redirect from="/index.php" to="/" />
                    <Redirect from="/index.php/Page/valeur_immobiliere" to="/" />
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="*">
                        <NoMatch />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}
